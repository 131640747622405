<template>
  <div>
    <div id="pageRegistPassword" class="mainContents">
      <section class="titleArea">
        <h2>新しいパスワードを設定</h2>
        <!-- /.titleArea -->
      </section>

      <form @submit.prevent="reminder">
        <div class="passwordArea">
          <div class="inner">
            <dl>
              <div>
                <dt>新しいパスワード</dt>
                <dd class="inputWrap">
                  <input
                    v-model="password1"
                    type="password"
                    placeholder="＊＊＊＊＊＊＊＊"
                  >
                </dd>
              </div>
              <div>
                <dt>再入力</dt>
                <dd class="inputWrap">
                  <input
                    v-model="password2"
                    type="password"
                    placeholder="＊＊＊＊＊＊＊＊"
                  >
                </dd>
              </div>
            </dl>
            <div class="buttonArea">
              <p>
                <button type="submit">
                  送信
                </button>
              </p>
              <!-- /.buttonArea -->
            </div>
          </div>
          <!-- /.loginArea -->
        </div>
        <input :value="id" type="hidden" name="id">
      </form>
      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      default: null,
      type: String
    }
  },
  data () {
    return {
      password1: '',
      password2: '',
      error: null
    }
  },
  methods: {
    reminder () {
      this.$store
        .dispatch('resetPassword', {
          password1: this.password1,
          password2: this.password2,
          id: this.id
        })
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch(err => {
          this.error = err.response.data.error
        })
    }
  }
}
</script>

<style scoped></style>
